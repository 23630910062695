import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';

import Section from 'components/common/section/Section';
import { Wrapper } from 'components/common/wrapper/Wrapper';
import FaqInfoPanel from 'components/directus/faq-info-panel/FaqInfoPanel';
import MainBanner from 'components/directus/main-banner/MainBanner';
import PageTitle from 'components/directus/page-title/PageTitle';
import ServiceContent from 'components/directus/service-content/ServiceContent';
import { DirectusPageData } from 'interfaces/directus-page-data.interface';
import { getFieldBySlug } from 'utils/directus';
import { PageProps } from 'utils/gatsby.interface';

export default ({ data }: PageProps<DirectusPageData>): JSX.Element => {
    const slug = 'go-digital';
    const { node } = getFieldBySlug(slug, data.allDirectusPage.edges);

    return (
        <>
            <Helmet>
                <title>{node.title}</title>
                <meta title="title" content={node.title} />
                <meta name="description" content={node.description} />
                <meta name="robots" content={node.robots} />
                <link rel="canonical" href={node.canonical} />
            </Helmet>
            <MainBanner slug={slug} />
            <Wrapper>
                <Section bottom={0}>
                    <PageTitle slug={slug} />
                </Section>
                <Section>
                    <ServiceContent columns={2} slug={slug} emailSwitch />
                </Section>
                <Section>
                    <FaqInfoPanel slug={slug} />
                </Section>
            </Wrapper>
        </>
    );
};

export const query = graphql`
    query{
      allDirectusPage(filter:{slug:{eq: "go-digital"}}){
        edges{
          node{
            title
            description
            slug
            robots
            canonical
          }
        }
      }
    }
`;
